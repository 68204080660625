<template>
    <div id="popup-prescription"  class="popup-login">

			<div class="content">
        <div class="back-step" @click="previousStep()"></div>
        
				<span id="cross" @click="closePrescription()"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
         
        <div class="the-content">
          <div  class="step-prescription" :class="{active:step==1, prev:step==2}">
            <h3>Votre ordonnance</h3>
            <div v-if="prescriptions.length>0" class="prescription-option" @click="changeStep(2,'account')">Je choisis l'ordonnance depuis mon compte</div>
            <div @click="changeStep(2,'custom')" class="prescription-option">Renseigner ma correction</div>
            <!--<div class="prescription-option" @click="changeStep(2,'file')">Je télécharge mon ordonnance</div>-->
          </div>
          <div class="step-prescription" :class="{active:step==2 && currentOption=='file', next:step==1, prev:step==3 && currentOption=='file'}">
            <h3>Votre ordonnance</h3><br /><br />
              <label>Choisissez un fichier à envoyer</label>
            <input type="file" @change="fileAdded($event)"  /><br /><br />
            <label>Donnez un nom à votre ordonnance</label>
            <input type="text" v-model="form.name" /><br /><br />
            <button @click="sendPrescriptionFile()">Envoyer mon ordonnance</button>
          </div>
          <div v-if="step==2 && currentOption=='account'">
            <h3>Votre ordonnance</h3><br /><br />
              Choisissez une ordonnance existante :<br /><br />
              <div v-for="prescription in prescriptions" :key="prescription.id" class="item" @click="choosePrescription(prescription.id)">
                <span class="name">{{prescription.name}}</span>
              
              </div>
          </div>
          <div class="step-prescription" :class="{active:step==2 && currentOption=='custom',prev:step==3 && currentOption=='custom'}">
            <h3>Votre ordonnance</h3>
            <div class="left">
              <h4>Oeil droit (OD)</h4>
              <label>Sphère</label>
          
              <select class="custom-select" v-model="form.right_sphere">
                <option  value="10">+10.00</option>
                <option value="9.75">+9.75</option>
                <option  value="9.5">+9.50</option>
                <option  value="9.25">+9.25</option>
                <option value="9">+9.00</option>
                <option  value="8.75">+8.75</option>
                <option  value="8.5">+8.50</option>
                <option  value="8.25">+8.25</option>
                <option  value="8">+8.00</option>
                <option  value="7.75">+7.75</option>
                <option  value="7.5">+7.50</option>
                <option value="7.25">+7.25</option>
                <option value="7">+7.00</option>
                <option  value="6.75">+6.75</option>
                <option value="6.5">+6.50</option>
                <option value="6.25">+6.25</option>
                <option value="6">+6.00</option>
                <option value="5.75">+5.75</option>
                <option value="5.5">+5.50</option>
                <option  value="5.25">+5.25</option>
                <option value="5">+5.00</option>
                <option value="4.75">+4.75</option>
                <option value="4.5">+4.50</option>
                <option value="4.25">+4.25</option>
                <option value="4">+4.00</option>
                <option value="3.75">+3.75</option>
                <option value="3.5">+3.50</option>
                <option value="3.25">+3.25</option>
                <option value="3">+3.00</option>
                <option value="2.75">+2.75</option>
                <option value="2.5">+2.50</option>
                <option value="2.25">+2.25</option>
                <option  value="2">+2.00</option>
                <option  value="1.75">+1.75</option>
                <option  value="1.5">+1.50</option>
                <option value="1.25">+1.25</option>
                <option  value="1">+1.00</option>
                <option  value="0.75">+0.75</option>
                <option value="0.5">+0.50</option>
                <option  value="0.25">+0.25</option>
                <option  value="0">0</option>
                <option  value="-0.25">-0.25</option>
                <option  value="-0.5">-0.50</option>
                <option value="-0.75">-0.75</option>
                <option value="-1">-1.00</option>
                <option  value="-1.25">-1.25</option>
                <option value="-1.5">-1.50</option>
                <option  value="-1.75">-1.75</option>
                <option  value="-2">-2.00</option>
                <option value="-2.25">-2.25</option>
                <option value="-2.5">-2.50</option>
                <option  value="-2.75">-2.75</option>
                <option value="-3">-3.00</option>
                <option value="-3.25">-3.25</option>
                <option  value="-3.5">-3.50</option>
                <option  value="-3.75">-3.75</option>
                <option  value="-4">-4.00</option>
                <option  value="-4.25">-4.25</option>
                <option  value="-4.5">-4.50</option>
                <option value="-4.75">-4.75</option>
                <option  value="-5">-5.00</option>
                <option  value="-5.25">-5.25</option>
                <option  value="-5.5">-5.50</option>
                <option  value="-5.75">-5.75</option>
                <option  value="-6">-6.00</option>
                <option  value="-6.25">-6.25</option>
                <option  value="-6.5">-6.50</option>
                <option value="-6.75">-6.75</option>
                <option value="-7">-7.00</option>
                <option  value="-7.25">-7.25</option>
                <option value="-7.5">-7.50</option>
                <option value="-7.75">-7.75</option>
                <option value="-8">-8.00</option>
                <option value="-8.25">-8.25</option>
                <option value="-8.5">-8.50</option>
                <option  value="-8.75">-8.75</option>
                <option value="-9">-9.00</option>
                <option  value="-9.25">-9.25</option>
                <option  value="-9.5">-9.50</option>
                <option value="-9.75">-9.75</option>
                <option  value="-10">-10.00</option>
              </select>
              
              <label>Cylindre</label>
            
              <select v-model="form.right_cylinder" class="custom-select">
                <option value="4">+4.00</option>
                <option  value="3.75">+3.75</option>
                <option  value="3.5">+3.50</option>
                <option  value="3.25">+3.25</option>
                <option value="3">+3.00</option>
                <option  value="2.75">+2.75</option>
                <option value="2.5">+2.50</option>
                <option  value="2.25">+2.25</option>
                <option  value="2">+2.00</option>
                <option value="1.75">+1.75</option>
                <option  value="1.5">+1.50</option>
                <option  value="1.25">+1.25</option>
                <option  value="1">+1.00</option>
                <option  value="0.75">+0.75</option>
                <option  value="0.5">+0.50</option>
                <option  value="0.25">+0.25</option>
                <option  value="0">0</option>
                <option value="-0.25">-0.25</option>
                <option  value="-0.5">-0.50</option>
                <option  value="-0.75">-0.75</option>
                <option  value="-1">-1.00</option>
                <option value="-1.25">-1.25</option>
                <option value="-1.5">-1.50</option>
                <option value="-1.75">-1.75</option>
                <option value="-2">-2.00</option>
                <option value="-2.25">-2.25</option>
                <option value="-2.5">-2.50</option>
                <option value="-2.75">-2.75</option>
                <option value="-3">-3.00</option>
                <option value="-3.25">-3.25</option>
                <option value="-3.5">-3.50</option>
                <option value="-3.75">-3.75</option>
                <option value="-4">-4.00</option>
              </select>

              <label>Axe</label>
            
              <select v-model="form.right_axis"  class="custom-select">
                <option value="-">-</option>
                <option  value="0">0°</option>
                <option value="1">1°</option>
                <option  value="2">2°</option>
                <option  value="3">3°</option>
                <option  value="4">4°</option>
                <option  value="5">5°</option>
                <option  value="6">6°</option>
                <option  value="7">7°</option>
                <option value="8">8°</option>
                <option  value="9">9°</option>
                <option value="10">10°</option>
                <option value="11">11°</option>
                <option  value="12">12°</option>
                <option value="13">13°</option>
                <option value="14">14°</option>
                <option  value="15">15°</option>
                <option value="16">16°</option>
                <option  value="17">17°</option>
                <option  value="18">18°</option>
                <option  value="19">19°</option>
                <option  value="20">20°</option>
                <option value="21">21°</option>
                <option  value="22">22°</option>
                <option value="23">23°</option>
                <option  value="24">24°</option>
                <option  value="25">25°</option>
                <option  value="26">26°</option>
                <option  value="27">27°</option>
                <option  value="28">28°</option>
                <option  value="29">29°</option>
                <option value="30">30°</option>
                <option  value="31">31°</option>
                <option  value="32">32°</option>
                <option  value="33">33°</option>
                <option  value="34">34°</option>
                <option  value="35">35°</option>
                <option  value="36">36°</option>
                <option value="37">37°</option>
                <option  value="38">38°</option>
                <option value="39">39°</option>
                <option  value="40">40°</option>
                <option value="41">41°</option>
                <option  value="42">42°</option>
                <option value="43">43°</option>
                <option  value="44">44°</option>
                <option value="45">45°</option>
                <option value="46">46°</option>
                <option  value="47">47°</option>
                <option  value="48">48°</option>
                <option value="49">49°</option>
                <option  value="50">50°</option>
                <option value="51">51°</option>
                <option  value="52">52°</option>
                <option  value="53">53°</option>
                <option  value="54">54°</option>
                <option  value="55">55°</option>
                <option value="56">56°</option>
                <option value="57">57°</option>
                <option  value="58">58°</option>
                <option value="59">59°</option>
                <option  value="60">60°</option>
                <option  value="61">61°</option>
                <option  value="62">62°</option>
                <option value="63">63°</option>
                <option  value="64">64°</option>
                <option  value="65">65°</option>
                <option value="66">66°</option>
                <option value="67">67°</option>
                <option  value="68">68°</option>
                <option value="69">69°</option>
                <option value="70">70°</option>
                <option  value="71">71°</option>
                <option  value="72">72°</option>
                <option  value="73">73°</option>
                <option value="74">74°</option>
                <option  value="75">75°</option>
                <option value="76">76°</option>
                <option  value="77">77°</option>
                <option value="78">78°</option>
                <option  value="79">79°</option>
                <option value="80">80°</option>
                <option  value="81">81°</option>
                <option value="82">82°</option>
                <option  value="83">83°</option>
                <option  value="84">84°</option>
                <option  value="85">85°</option>
                <option value="86">86°</option>
                <option  value="87">87°</option>
                <option value="88">88°</option>
                <option  value="89">89°</option>
                <option  value="90">90°</option>
                <option  value="91">91°</option>
                <option  value="92">92°</option>
                <option value="93">93°</option>
                <option value="94">94°</option>
                <option  value="95">95°</option>
                <option value="96">96°</option>
                <option  value="97">97°</option>
                <option value="98">98°</option>
                <option value="99">99°</option>
                <option value="100">100°</option>
                <option value="101">101°</option>
                <option value="102">102°</option>
                <option value="103">103°</option>
                <option value="104">104°</option>
                <option value="105">105°</option>
                <option  value="106">106°</option>
                <option value="107">107°</option>
                <option  value="108">108°</option>
                <option  value="109">109°</option>
                <option  value="110">110°</option>
                <option  value="111">111°</option>
                <option value="112">112°</option>
                <option  value="113">113°</option>
                <option  value="114">114°</option>
                <option  value="115">115°</option>
                <option  value="116">116°</option>
                <option  value="117">117°</option>
                <option  value="118">118°</option>
                <option  value="119">119°</option>
                <option  value="120">120°</option>
                <option  value="121">121°</option>
                <option  value="122">122°</option>
                <option value="123">123°</option>
                <option  value="124">124°</option>
                <option value="125">125°</option>
                <option  value="126">126°</option>
                <option  value="127">127°</option>
                <option  value="128">128°</option>
                <option value="129">129°</option>
                <option  value="130">130°</option>
                <option value="131">131°</option>
                <option  value="132">132°</option>
                <option  value="133">133°</option>
                <option  value="134">134°</option>
                <option  value="135">135°</option>
                <option  value="136">136°</option>
                <option  value="137">137°</option>
                <option  value="138">138°</option>
                <option value="139">139°</option>
                <option  value="140">140°</option>
                <option  value="141">141°</option>
                <option  value="142">142°</option>
                <option  value="143">143°</option>
                <option  value="144">144°</option>
                <option value="145">145°</option>
                <option  value="146">146°</option>
                <option  value="147">147°</option>
                <option value="148">148°</option>
                <option value="149">149°</option>
                <option value="150">150°</option>
                <option value="151">151°</option>
                <option  value="152">152°</option>
                <option value="153">153°</option>
                <option  value="154">154°</option>
                <option  value="155">155°</option>
                <option  value="156">156°</option>
                <option  value="157">157°</option>
                <option  value="158">158°</option>
                <option  value="159">159°</option>
                <option  value="160">160°</option>
                <option value="161">161°</option>
                <option value="162">162°</option>
                <option value="163">163°</option>
                <option  value="164">164°</option>
                <option  value="165">165°</option>
                <option value="166">166°</option>
                <option value="167">167°</option>
                <option value="168">168°</option>
                <option  value="169">169°</option>
                <option  value="170">170°</option>
                <option  value="171">171°</option>
                <option value="172">172°</option>
                <option value="173">173°</option>
                <option value="174">174°</option>
                <option  value="175">175°</option>
                <option  value="176">176°</option>
                <option  value="177">177°</option>
                <option  value="178">178°</option>
                <option  value="179">179°</option>
                <option value="180">180°</option>
              </select>

              <h4 class="pupillary">Ecart pupillaire</h4>

              <label>Optionnel</label>

              <div class="checkbox" v-bind:class="{active:form.full_pupillary_distance==true}" @click="checkItem('full_pupillary_distance')" > 
              
                <label>Ecart pupillaire total</label>
                
              </div>

              
              
              <label>Ecart pupillaire</label>
              
              <select  v-if="form.semi_pupillary_distance==true" class="custom-select"  v-model="form.right_pupillary_distance" >
                <option value="-">-</option>
                <option  value="23">23.00</option>
                <option  value="23.5">23.50</option>
                <option  value="24">24.00</option>
                <option  value="24.5">24.50</option>
                <option  value="25">25.00</option>
                <option value="25.5">25.50</option>
                <option value="26">26.00</option>
                <option  value="26.5">26.50</option>
                <option  value="27">27.00</option>
                <option value="27.5">27.50</option>
                <option  value="28">28.00</option>
                <option value="28.5">28.50</option>
                <option  value="29">29.00</option>
                <option value="29.5">29.50</option>
                <option  value="30">30.00</option>
                <option  value="30.5">30.50</option>
                <option  value="31">31.00</option>
                <option value="31.5">31.50</option>
                <option value="32">32.00</option>
                <option  value="32.5">32.50</option>
                <option  value="33">33.00</option>
                <option  value="33.5">33.50</option>
                <option  value="34">34.00</option>
                <option  value="34.5">34.50</option>
                <option value="35">35.00</option>
                <option  value="35.5">35.50</option>
                <option  value="36">36.00</option>
                <option  value="36.5">36.50</option>
                <option value="37">37.00</option>
                <option  value="37.5">37.50</option>
                <option value="38">38.00</option>
                <option  value="38.5">38.50</option>
                <option  value="39">39.00</option>
                <option  value="39.5">39.50</option>
                <option value="40">40.00</option
                ><option  value="40.5">40.50</option>
                <option value="41">41.00</option>
                <option  value="41.5">41.50</option>
                <option value="42">42.00</option>
                <option value="42.5">42.50</option>
                <option value="43">43.00</option>
              </select>

              <select  v-else class="custom-select"  v-model="form.pupillary_distance" >
                <option value="-">-</option>
                <option value="46">46.00</option>
                <option value="46.5">46.50</option>
                <option value="47">47.00</option>
                <option value="47.5">47.50</option>
                <option value="48">48.00</option>
                <option value="48.5">48.50</option>
                <option value="49">49.00</option>
                <option value="49.5">49.50</option>
                <option value="50">50.00</option>
                <option value="50.5">50.50</option>
                <option value="51">51.00</option>
                <option value="51.5">51.50</option>
                <option value="52">52.00</option>
                <option value="52.5">52.50</option>
                <option value="53">53.00</option>
                <option value="53.5">53.50</option>
                <option value="54">54.00</option>
                <option value="54.5">54.50</option>
                <option value="55">55.00</option>
                <option value="55.5">55.50</option>
                <option value="56">56.00</option>
                <option value="56.5">56.50</option>
                <option value="57">57.00</option>
                <option value="57.5">57.50</option>
                <option value="58">58.00</option>
                <option value="58.5">58.50</option>
                <option value="59">59.00</option>
                <option value="59.5">59.50</option>
                <option value="60">60.00</option>
                <option value="60.5">60.50</option>
                <option value="61">61.00</option>
                <option value="61.5">61.50</option>
                <option value="62">62.00</option>
                <option value="62.5">62.50</option>
                <option value="63">63.00</option>
                <option value="63.5">63.50</option>
                <option value="64">64.00</option>
                <option value="64.5">64.50</option>
                <option value="65">65.00</option>
                <option value="65.5">65.50</option>
                <option value="66">66.00</option>
                <option value="66.5">66.50</option>
                <option value="67">67.00</option>
                <option value="67.5">67.50</option>
                <option value="68">68.00</option>
                <option value="68.5">68.50</option>
                <option value="69">69.00</option>
                <option value="69.5">69.50</option>
                <option value="70">70.00</option>
                <option value="70.5">70.50</option>
                <option value="71">71.00</option>
                <option value="71.5">71.50</option>
                <option value="72">72.00</option>
                <option value="72.5">72.50</option>
                <option value="73">73.00</option>
                <option value="73.5">73.50</option>
                <option value="74">74.00</option>
                <option value="74.5">74.50</option>
                <option value="75">75.00</option>
                <option value="75.5">75.50</option>
                <option value="76">76.00</option>
                <option value="76.5">76.50</option>
                <option value="77">77.00</option>
                <option value="77.5">77.50</option>
                <option value="78">78.00</option>
                <option value="78.5">78.50</option>
                <option value="79">79.00</option>
                <option value="79.5">79.50</option>
                <option value="80">80.00</option>
                <option value="80.5">80.50</option>
                <option value="81">81.00</option>
                <option value="81.5">81.50</option>
                <option value="82">82.00</option>
                <option value="82.5">82.50</option>
                <option value="83">83.00</option>
                <option value="83.5">83.50</option>
                <option value="84">84.00</option>
                <option value="84.5">84.50</option>
                <option value="85">85.00</option>
                <option value="85.5">85.50</option>
                <option value="86">86.00</option>
                
              </select>
              

              <!--<div class="checkbox" v-bind:class="{active:form.prism==true}" @click="checkItem('prism')"> <label>Prisme</label></div>-->


              <select class="custom-select" v-if="form.prism==true" v-model="form.right_prism" >
                <option value="-">-</option>
                <option  value="0.25">0.25</option>
                <option  value="0.5">0.50</option>
                <option  value="0.75">0.75</option>
                <option  value="1">1.00</option>
                <option  value="1.25">1.25</option>
                <option value="1.5">1.50</option>
                <option value="1.75">1.75</option>
                <option  value="2">2.00</option>
                <option  value="2.25">2.25</option>
                <option  value="2.5">2.50</option>
                <option  value="2.75">2.75</option>
                <option  value="3">3.00</option>
                <option  value="3.25">3.25</option>
                <option  value="3.5">3.50</option>
                <option  value="3.75">3.75</option>
                <option  value="4">4.00</option>
                <option  value="4.25">4.25</option>
                <option value="4.5">4.50</option>
                <option value="4.75">4.75</option>
                <option value="5">5.00</option>
              </select>

              <label  v-if="form.prism==true">Addition</label>

              <select class="custom-select"   v-if="form.prism==true" v-model="form.right_addition">
                <option value="top">90º(Supérieur)</option> 
                <option value="bottom">270º(Inférieur)</option> 
                <option value="in">0º(Nasal)</option> 
                <option value="out">180º(Temporal)</option>
              </select>
            </div>
            <div class="right">
              <h4>Oeil gauche (OG)</h4>
              <label>Sphère</label>

              <select class="custom-select" v-model="form.left_sphere">
                <option  value="10">+10.00</option>
                <option value="9.75">+9.75</option>
                <option  value="9.5">+9.50</option>
                <option  value="9.25">+9.25</option>
                <option value="9">+9.00</option>
                <option  value="8.75">+8.75</option>
                <option  value="8.5">+8.50</option>
                <option  value="8.25">+8.25</option>
                <option  value="8">+8.00</option>
                <option  value="7.75">+7.75</option>
                <option  value="7.5">+7.50</option>
                <option value="7.25">+7.25</option>
                <option value="7">+7.00</option>
                <option  value="6.75">+6.75</option>
                <option value="6.5">+6.50</option>
                <option value="6.25">+6.25</option>
                <option value="6">+6.00</option>
                <option value="5.75">+5.75</option>
                <option value="5.5">+5.50</option>
                <option  value="5.25">+5.25</option>
                <option value="5">+5.00</option>
                <option value="4.75">+4.75</option>
                <option value="4.5">+4.50</option>
                <option value="4.25">+4.25</option>
                <option value="4">+4.00</option>
                <option value="3.75">+3.75</option>
                <option value="3.5">+3.50</option>
                <option value="3.25">+3.25</option>
                <option value="3">+3.00</option>
                <option value="2.75">+2.75</option>
                <option value="2.5">+2.50</option>
                <option value="2.25">+2.25</option>
                <option  value="2">+2.00</option>
                <option  value="1.75">+1.75</option>
                <option  value="1.5">+1.50</option>
                <option value="1.25">+1.25</option>
                <option  value="1">+1.00</option>
                <option  value="0.75">+0.75</option>
                <option value="0.5">+0.50</option>
                <option  value="0.25">+0.25</option>
                <option  value="0">0</option>
                <option  value="-0.25">-0.25</option>
                <option  value="-0.5">-0.50</option>
                <option value="-0.75">-0.75</option>
                <option value="-1">-1.00</option>
                <option  value="-1.25">-1.25</option>
                <option value="-1.5">-1.50</option>
                <option  value="-1.75">-1.75</option>
                <option  value="-2">-2.00</option>
                <option value="-2.25">-2.25</option>
                <option value="-2.5">-2.50</option>
                <option  value="-2.75">-2.75</option>
                <option value="-3">-3.00</option>
                <option value="-3.25">-3.25</option>
                <option  value="-3.5">-3.50</option>
                <option  value="-3.75">-3.75</option>
                <option  value="-4">-4.00</option>
                <option  value="-4.25">-4.25</option>
                <option  value="-4.5">-4.50</option>
                <option value="-4.75">-4.75</option>
                <option  value="-5">-5.00</option>
                <option  value="-5.25">-5.25</option>
                <option  value="-5.5">-5.50</option>
                <option  value="-5.75">-5.75</option>
                <option  value="-6">-6.00</option>
                <option  value="-6.25">-6.25</option>
                <option  value="-6.5">-6.50</option>
                <option value="-6.75">-6.75</option>
                <option value="-7">-7.00</option>
                <option  value="-7.25">-7.25</option>
                <option value="-7.5">-7.50</option>
                <option value="-7.75">-7.75</option>
                <option value="-8">-8.00</option>
                <option value="-8.25">-8.25</option>
                <option value="-8.5">-8.50</option>
                <option  value="-8.75">-8.75</option>
                <option value="-9">-9.00</option>
                <option  value="-9.25">-9.25</option>
                <option  value="-9.5">-9.50</option>
                <option value="-9.75">-9.75</option>
                <option  value="-10">-10.00</option>
              </select>

              <label>Cylindre</label>
            
              <select v-model="form.left_cylinder" class="custom-select">
                <option value="4">+4.00</option>
                <option  value="3.75">+3.75</option>
                <option  value="3.5">+3.50</option>
                <option  value="3.25">+3.25</option>
                <option value="3">+3.00</option>
                <option  value="2.75">+2.75</option>
                <option value="2.5">+2.50</option>
                <option  value="2.25">+2.25</option>
                <option  value="2">+2.00</option>
                <option value="1.75">+1.75</option>
                <option  value="1.5">+1.50</option>
                <option  value="1.25">+1.25</option>
                <option  value="1">+1.00</option>
                <option  value="0.75">+0.75</option>
                <option  value="0.5">+0.50</option>
                <option  value="0.25">+0.25</option>
                <option  value="0">0</option>
                <option value="-0.25">-0.25</option>
                <option  value="-0.5">-0.50</option>
                <option  value="-0.75">-0.75</option>
                <option  value="-1">-1.00</option>
                <option value="-1.25">-1.25</option>
                <option value="-1.5">-1.50</option>
                <option value="-1.75">-1.75</option>
                <option value="-2">-2.00</option>
                <option value="-2.25">-2.25</option>
                <option value="-2.5">-2.50</option>
                <option value="-2.75">-2.75</option>
                <option value="-3">-3.00</option>
                <option value="-3.25">-3.25</option>
                <option value="-3.5">-3.50</option>
                <option value="-3.75">-3.75</option>
                <option value="-4">-4.00</option>
              </select>

              <label>Axe</label>

              <select v-model="form.left_axis"  class="custom-select">
                <option value="-">-</option>
                <option  value="0">0°</option>
                <option value="1">1°</option>
                <option  value="2">2°</option>
                <option  value="3">3°</option>
                <option  value="4">4°</option>
                <option  value="5">5°</option>
                <option  value="6">6°</option>
                <option  value="7">7°</option>
                <option value="8">8°</option>
                <option  value="9">9°</option>
                <option value="10">10°</option>
                <option value="11">11°</option>
                <option  value="12">12°</option>
                <option value="13">13°</option>
                <option value="14">14°</option>
                <option  value="15">15°</option>
                <option value="16">16°</option>
                <option  value="17">17°</option>
                <option  value="18">18°</option>
                <option  value="19">19°</option>
                <option  value="20">20°</option>
                <option value="21">21°</option>
                <option  value="22">22°</option>
                <option value="23">23°</option>
                <option  value="24">24°</option>
                <option  value="25">25°</option>
                <option  value="26">26°</option>
                <option  value="27">27°</option>
                <option  value="28">28°</option>
                <option  value="29">29°</option>
                <option value="30">30°</option>
                <option  value="31">31°</option>
                <option  value="32">32°</option>
                <option  value="33">33°</option>
                <option  value="34">34°</option>
                <option  value="35">35°</option>
                <option  value="36">36°</option>
                <option value="37">37°</option>
                <option  value="38">38°</option>
                <option value="39">39°</option>
                <option  value="40">40°</option>
                <option value="41">41°</option>
                <option  value="42">42°</option>
                <option value="43">43°</option>
                <option  value="44">44°</option>
                <option value="45">45°</option>
                <option value="46">46°</option>
                <option  value="47">47°</option>
                <option  value="48">48°</option>
                <option value="49">49°</option>
                <option  value="50">50°</option>
                <option value="51">51°</option>
                <option  value="52">52°</option>
                <option  value="53">53°</option>
                <option  value="54">54°</option>
                <option  value="55">55°</option>
                <option value="56">56°</option>
                <option value="57">57°</option>
                <option  value="58">58°</option>
                <option value="59">59°</option>
                <option  value="60">60°</option>
                <option  value="61">61°</option>
                <option  value="62">62°</option>
                <option value="63">63°</option>
                <option  value="64">64°</option>
                <option  value="65">65°</option>
                <option value="66">66°</option>
                <option value="67">67°</option>
                <option  value="68">68°</option>
                <option value="69">69°</option>
                <option value="70">70°</option>
                <option  value="71">71°</option>
                <option  value="72">72°</option>
                <option  value="73">73°</option>
                <option value="74">74°</option>
                <option  value="75">75°</option>
                <option value="76">76°</option>
                <option  value="77">77°</option>
                <option value="78">78°</option>
                <option  value="79">79°</option>
                <option value="80">80°</option>
                <option  value="81">81°</option>
                <option value="82">82°</option>
                <option  value="83">83°</option>
                <option  value="84">84°</option>
                <option  value="85">85°</option>
                <option value="86">86°</option>
                <option  value="87">87°</option>
                <option value="88">88°</option>
                <option  value="89">89°</option>
                <option  value="90">90°</option>
                <option  value="91">91°</option>
                <option  value="92">92°</option>
                <option value="93">93°</option>
                <option value="94">94°</option>
                <option  value="95">95°</option>
                <option value="96">96°</option>
                <option  value="97">97°</option>
                <option value="98">98°</option>
                <option value="99">99°</option>
                <option value="100">100°</option>
                <option value="101">101°</option>
                <option value="102">102°</option>
                <option value="103">103°</option>
                <option value="104">104°</option>
                <option value="105">105°</option>
                <option  value="106">106°</option>
                <option value="107">107°</option>
                <option  value="108">108°</option>
                <option  value="109">109°</option>
                <option  value="110">110°</option>
                <option  value="111">111°</option>
                <option value="112">112°</option>
                <option  value="113">113°</option>
                <option  value="114">114°</option>
                <option  value="115">115°</option>
                <option  value="116">116°</option>
                <option  value="117">117°</option>
                <option  value="118">118°</option>
                <option  value="119">119°</option>
                <option  value="120">120°</option>
                <option  value="121">121°</option>
                <option  value="122">122°</option>
                <option value="123">123°</option>
                <option  value="124">124°</option>
                <option value="125">125°</option>
                <option  value="126">126°</option>
                <option  value="127">127°</option>
                <option  value="128">128°</option>
                <option value="129">129°</option>
                <option  value="130">130°</option>
                <option value="131">131°</option>
                <option  value="132">132°</option>
                <option  value="133">133°</option>
                <option  value="134">134°</option>
                <option  value="135">135°</option>
                <option  value="136">136°</option>
                <option  value="137">137°</option>
                <option  value="138">138°</option>
                <option value="139">139°</option>
                <option  value="140">140°</option>
                <option  value="141">141°</option>
                <option  value="142">142°</option>
                <option  value="143">143°</option>
                <option  value="144">144°</option>
                <option value="145">145°</option>
                <option  value="146">146°</option>
                <option  value="147">147°</option>
                <option value="148">148°</option>
                <option value="149">149°</option>
                <option value="150">150°</option>
                <option value="151">151°</option>
                <option  value="152">152°</option>
                <option value="153">153°</option>
                <option  value="154">154°</option>
                <option  value="155">155°</option>
                <option  value="156">156°</option>
                <option  value="157">157°</option>
                <option  value="158">158°</option>
                <option  value="159">159°</option>
                <option  value="160">160°</option>
                <option value="161">161°</option>
                <option value="162">162°</option>
                <option value="163">163°</option>
                <option  value="164">164°</option>
                <option  value="165">165°</option>
                <option value="166">166°</option>
                <option value="167">167°</option>
                <option value="168">168°</option>
                <option  value="169">169°</option>
                <option  value="170">170°</option>
                <option  value="171">171°</option>
                <option value="172">172°</option>
                <option value="173">173°</option>
                <option value="174">174°</option>
                <option  value="175">175°</option>
                <option  value="176">176°</option>
                <option  value="177">177°</option>
                <option  value="178">178°</option>
                <option  value="179">179°</option>
                <option value="180">180°</option>
              </select>

              <h4 class="pupillary">&nbsp;</h4>
              <label>&nbsp;</label>
              <div class="checkbox" v-bind:class="{active:form.semi_pupillary_distance==true}" @click="checkItem('semi_pupillary_distance')"> <label>Demi-écart pupillaire</label></div>

              
              <label  v-if="form.semi_pupillary_distance==true" >Ecart pupillaire</label>

            
              <select v-if="form.semi_pupillary_distance==true" class="custom-select"  v-model="form.left_pupillary_distance" >
                <option value="-">-</option>
                <option  value="23">23.00</option>
                <option  value="23.5">23.50</option>
                <option  value="24">24.00</option>
                <option  value="24.5">24.50</option>
                <option  value="25">25.00</option>
                <option value="25.5">25.50</option>
                <option value="26">26.00</option>
                <option  value="26.5">26.50</option>
                <option  value="27">27.00</option>
                <option value="27.5">27.50</option>
                <option  value="28">28.00</option>
                <option value="28.5">28.50</option>
                <option  value="29">29.00</option>
                <option value="29.5">29.50</option>
                <option  value="30">30.00</option>
                <option  value="30.5">30.50</option>
                <option  value="31">31.00</option>
                <option value="31.5">31.50</option>
                <option value="32">32.00</option>
                <option  value="32.5">32.50</option>
                <option  value="33">33.00</option>
                <option  value="33.5">33.50</option>
                <option  value="34">34.00</option>
                <option  value="34.5">34.50</option>
                <option value="35">35.00</option>
                <option  value="35.5">35.50</option>
                <option  value="36">36.00</option>
                <option  value="36.5">36.50</option>
                <option value="37">37.00</option>
                <option  value="37.5">37.50</option>
                <option value="38">38.00</option>
                <option  value="38.5">38.50</option>
                <option  value="39">39.00</option>
                <option  value="39.5">39.50</option>
                <option value="40">40.00</option
                ><option  value="40.5">40.50</option>
                <option value="41">41.00</option>
                <option  value="41.5">41.50</option>
                <option value="42">42.00</option>
                <option value="42.5">42.50</option>
                <option value="43">43.00</option>
              </select>

              <div v-else><br /><br /></div>
              

              <br /><br />
              <select  v-if="form.prism==true" class="custom-select" v-model="form.left_prism" >
                <option value="-">-</option>
                <option  value="0.25">0.25</option>
                <option  value="0.5">0.50</option>
                <option  value="0.75">0.75</option>
                <option  value="1">1.00</option>
                <option  value="1.25">1.25</option>
                <option value="1.5">1.50</option>
                <option value="1.75">1.75</option>
                <option  value="2">2.00</option>
                <option  value="2.25">2.25</option>
                <option  value="2.5">2.50</option>
                <option  value="2.75">2.75</option>
                <option  value="3">3.00</option>
                <option  value="3.25">3.25</option>
                <option  value="3.5">3.50</option>
                <option  value="3.75">3.75</option>
                <option  value="4">4.00</option>
                <option  value="4.25">4.25</option>
                <option value="4.5">4.50</option>
                <option value="4.75">4.75</option>
                <option value="5">5.00</option>
              </select>

              <label  v-if="form.prism==true">Addition</label>
            
              <select  v-if="form.prism==true" class="custom-select"  v-model="form.left_addition">
                <option value="top">90º(Supérieur)</option> 
                <option value="bottom">270º(Inférieur)</option> 
                <option value="in">0º(Nasal)</option> 
                <option value="out">180º(Temporal)</option>
              </select>
              
            </div>

            <label>Donnez un nom à votre ordonnance</label>
            <input type="text" v-model="form.name" />

            <button @click="sendPrescription()">Envoyer ma correction</button>
          
          </div>

          <div  class="step-prescription" :class="{active:step==3, next:step==2, prev:step==4}">
            <h3>Affinement de vos verres</h3>

            <div v-for="refinement in refinements" :key="refinement.id" class="item" @click="chooseRefinement(refinement.id, refinement.price)">
              <span class="name">{{refinement.name}}</span>
              <span class="decription">{{refinement.description}}</span>
              <span class="price"><span v-if="refinement.price>0">{{refinement.price}}€</span><span v-else>Offert</span></span>
            </div>
          </div>

          <div class="step-prescription" :class="{active:step==4, next:step==3}">
            <h3>Traitement de vos verres</h3>
            <div v-for="processing in processings" :key="processing.id" class="item" @click="closePrescription(processing.id, processing.price)">
              <span class="name">{{processing.name}}</span>
              <span class="decription">{{processing.description}}</span>
              <span class="price"><span v-if="processing.price>0">{{processing.price}}€</span><span v-else>Offert</span></span>
            </div>
            
          </div>
        </div>

        <div class="bar" :style="'width:'+barPercent+'%;'">
          
        </div>
        <span class="price_total"><span class="total-label">Total</span> {{current_price}}€</span>
      </div>
		</div>
</template>


<script>
import {Auth, APIRequest} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'


export default {

  name: 'PopupApplication',
  data() {
    return {
     step:1,
     prescription_id:null,
     current_price:0,
     actualRefinement:null,
     loaded:false,
     actualProcessing:null,
     actualRefinementPrice:0,
     actualPrescription:null,
     actualProcessingPrice:0,
     refinements:[],
     processings:[],
     prescriptions:[],
     barPercent:25,
     currentOption:null,
     cart_item_form:null,
     form:{
       name:'',
       file:'',
       right_sphere:'0',
       right_cylinder:'0',
       right_addition:'',
       right_axis:'',
       left_sphere:'0',
       left_cylinder:'0',
       left_addition:'',
       left_axis:'',
       left_pupillary_distance:'',
       right_pupillary_distance:'',
       pupillary_distance:'',
       left_prism:'',
       right_prism:'',
       prism:false,
        full_pupillary_distance:false,
        semi_pupillary_distance:true,
        user:null,
     }
    }
  },
  props:['cart_item'],
  
  async mounted(){

    await this.getRefinements();
    await this.getProcessings();

    this.cart_item_form=await APIRequest.detail('order-items',this.cart_item)
    this.current_price=this.cart_item_form.amount
    if(localStorage.token_senseye_auth)
    {
      
      await this.getPrescriptions();
    }
    
    this.loaded=true
  },
  methods:{
    async getPrescriptions()
    {
      
      let user=this.parseJwt(localStorage.token_senseye_auth);
      let user_data=await Auth.get_user(user.username) 
      this.form.user={}
      this.form.user.id=user_data.id
      let prescriptions=await APIRequest.list('prescriptions',user_data.id)
      this.prescriptions=prescriptions[0];
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async loginUser(username,password)
    {
       let user=emitter.emit('login',username,password)
        
       if(user==null)
       {
          this.errorLogin='Vos identifiants sont incorrects.';
       }
           
    },
    async choosePrescription(prescription)
    {

      this.actualPrescription=prescription
      let cart_item=this.cart_item_form
      cart_item.prescription = {}
      cart_item.prescription.id=prescription
      this.cart_item_form=await APIRequest.update('order-items',this.cart_item, cart_item)
      this.step=3
      this.barPercent=this.step*25;
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
         
          let b64=reader.result.split(';')
          let type=b64[0]
          
          let base64=b64[1]
          //base64.replace('base64,','')
          this.form.file=reader.result
      };
    },
  
    async getRefinements()
    {
        let refinements=await APIRequest.list('refinements')
        this.refinements=refinements[0];
    },
    async getProcessings()
    {
        let processings=await APIRequest.list('processings')
        this.processings=processings[0];
    },
    checkItem(item){
      if(item=="prism")
      {
        if(this.form.prism==false)
        {
          this.form.prism=true
        }
        else
        {
          this.form.prism=false
        }
      }
      if(item=="full_pupillary_distance")
      {
        if(this.form.full_pupillary_distance==false)
        {
          this.form.full_pupillary_distance=true
          this.form.semi_pupillary_distance=false
        }
        else
        {
          this.form.full_pupillary_distance=false
          this.form.semi_pupillary_distance=true
        }
      }
      if(item=="semi_pupillary_distance")
      {
        if(this.form.semi_pupillary_distance==false)
        {
          this.form.semi_pupillary_distance=true
          this.form.full_pupillary_distance=false
        }
        else
        {
          this.form.semi_pupillary_distance=false
          this.form.full_pupillary_distance=true
        }
      }
    },
    async sendPrescriptionFile(){

      if(this.form.file!='' || this.form.name!='')
      {
        if(this.prescription_id!=null)
        {
          let prescription=await APIRequest.update('prescriptions',this.prescription_id,this.form)
        }
        else
        {
          let prescription=await APIRequest.create('prescriptions',this.form)
          this.prescription_id=prescription.id
          let cart_item=this.cart_item_form
          cart_item.prescription = {}
          cart_item.prescription.id=prescription.id
          this.cart_item_form=await APIRequest.update('order-items',this.cart_item, cart_item)
        }
        
       
        this.step=3
        this.barPercent=this.step*25;
      }
      else{
        alert('Vous devez sélectionner un fichier et renseigner un nom !')
      }
    },
    async sendPrescription(){

      if(this.form.name!="" && this.form.right_sphere!="" && this.form.right_cylinder!="" && this.form.right_axis!="" && this.form.left_sphere!="" && this.form.left_cylinder!="" && this.form.left_axis!="")
      {
        if(this.prescription_id!=null)
        {
          let prescription=await APIRequest.update('prescriptions',this.prescription_id,this.form)
        }
        else
        {
          let prescription=await APIRequest.create('prescriptions',this.form)
          this.prescription_id=prescription.id
          let cart_item=this.cart_item_form
          cart_item.prescription = {}
          cart_item.prescription.id=prescription.id
          this.cart_item_form=await APIRequest.update('order-items',this.cart_item, cart_item)
        }
        
        this.step=3
        this.barPercent=this.step*25;
      }
      else{
        alert('Certains champs sont manquants !')
      }

    },
    previousStep() {
      if(this.step!=1)
      {
        this.step -= 1
      }
      
    },
    chooseRefinement(refinement,price) {
      this.step=4
      this.barPercent=this.step*25;
      this.actualRefinement=refinement
      this.actualRefinementPrice=price
      this.current_price=this.current_price*1 + this.actualRefinementPrice*1
    },
    changeStep(step, option)
    {
      this.step=step
      this.currentOption=option
      this.barPercent=this.step*25;
    },
    async closePrescription(processing,price)
    {
      if(processing)
      {
        this.actualProcessing=processing
        this.actualProcessingPrice=price
        this.current_price=this.current_price*1 + this.actualProcessingPrice*1
        let cart_item=this.cart_item_form
        cart_item.processing = {}
        cart_item.processing.id=processing
        cart_item.amount=this.current_price
        this.cart_item_form=await APIRequest.update('order-items',this.cart_item, cart_item)
        emitter.emit('display-cart')
      }
      else
      {
        await APIRequest.delete('order-items',this.cart_item)
      }
      emitter.emit('update-cart')
      emitter.emit('close-prescription-popup')
    },
    closeModal($event)
    {
      if (jQuery($event.target).parents('.content').length) {

      }
      else
      {
        emitter.emit('close-prescription-popup')
      }
    },  
  },
}
</script>

<style scoped>
.step-prescription.prev
{
  position:fixed;
  display: block;
  opacity:0.4;
  right:5%;
  left:auto;
  pointer-events: none;
  transform: translateY(-100%);
  margin-top:100px;
  top:0vh;
  width:35%;
}
.step-prescription.next
{
  position:fixed;
  display: block;
  opacity:0.4;
  right:5%;
  left:auto;
  pointer-events: none;
  transform: translateY(-100px);
  top:100vh;
  width:35%;
}
.step-prescription.active
{
  display: block;

    top:50%;
    transform: translateY(-50%);
  animation: animsep 0.4s ease;
}
@keyframes animsep {

  from {
    opacity:0.4;
   transform: translateY(140%);
  }
  to {
    opacity:1;
    transform: translateY(-50%);
  }
  
}
.step-prescription
{
  display: none;
  position: absolute;
  top:0;
  left:0;
  width:100%;
}
.item .description
{
  margin-right:10px;
}
.item .price
{
  float:right;
}
.item .name
{
  font-weight: 700;
  display: block;
}
.item:hover
{
  background:rgba(0,0,0,0.1);
}
.back-step
{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  background:transparent;
  height:50px;
}
.item
{
  cursor: pointer;
  margin-top:10px;
  padding-top:20px;
   padding-bottom:20px;
  padding-left:15px;
  padding-right:15px;
  font-size:12px;
  border: 0.30000001192092896px solid #707070;
}
.register-form input,.login-form input,
.register-form select,.login-form select
{
  margin-bottom:20px;
}
#popup-prescription .the-content
{
  width: 80%;
  position:absolute;
  overflow: hidden;
  left:10%;
  top:10%;
  height:80%;
}
#popup-prescription
{
z-index: 9999999;
    position: fixed;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0vh;
    }
    #popup-prescription .content
    {
      background:#fff;
      position:absolute;
      right:0%;
      text-align: left;
      top: 0;
      min-width:45%;
      height:100%;
      padding:5%;
      
    }
    #popup-prescription .content .left
    {
      display: inline-block;
      width:49%;
      vertical-align: top;
      padding-right:10px;
    }
    #popup-prescription .content .right
    {
      
      display: inline-block;
      width:49%;
      vertical-align: top;
      padding-left:10px;
      text-align: left;
    }
    #popup-prescription #cross
    {
      cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    left:10px;
    right:auto;
    top:10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
#popup-prescription .prescription-option:hover
{
  background:rgba(0,0,0,0.1);
}
#popup-prescription .prescription-option
{
  background:#F8F8F8;
  border-radius: 8px;
  padding:15px;
  cursor:pointer;
  margin-top:20px;
  transition:all 0.5s;
}
#popup-prescription label
{
  font-size:12px;
}
#popup-prescription h4.pupillary
{
  margin-bottom:0;
}
#popup-prescription h4
{
  font-size:16px;
  margin-top:10px;
    margin-bottom:10px;
}
  #popup-prescription h2,#popup-prescription h3
  {
    font-size:23px;
    margin-top:0;
    margin-bottom:0;
  }
  #popup-prescription .bar
  {
    height:2px;
    position:absolute;
    left:0;
    bottom:40px;
    transition:all 0.5s;
    background:#000;
  }
  #popup-prescription input,
  #popup-prescription select
  {
    padding:10px 20px;
    font-size:12px;
    width:100%;
    margin-bottom:10px;
  }
  div.checkbox.active::before
  {
    background:#000;
  }
   div.checkbox::before
   {
     content:' ';
     position:absolute;
     left:0;
     border-radius: 50%;
     height:15px;
     width:15px;
     border:1px solid black;
   }
  div.checkbox
  {
    position: relative;
    padding-left:20px;
  }
  @media screen and (max-width:760px) {
  body .step-prescription.prev
  {
   
   
    right:10%;

    width:80%;
  }
  body .step-prescription.next
  {
    
    right:10%;

    width:80%;
  }
    body #popup-prescription .price_total
    {
      right:5%;
      left:auto;
      top:20px;
      bottom:auto;
    }
    body #popup-prescription .bar
    {
      top:60px;
      bottom:auto;
    }
    body #popup-prescription .content
    {
      padding-top:100px;
      min-width:100%;
    }
    
  }
  .price_total .total-label
  {
    font-size: 15px;
  }
    .price_total
  {
    position:absolute;
    right:5%;
    bottom:5px;
      font-size: 25px;
  }
  .loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:6;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
</style>
